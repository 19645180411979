.configuration {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  background: $background-modal;
  border: 1px solid $border;
  padding: 1rem;
  width: 400px;
  max-width: calc(100vw - 2rem);
  box-shadow: $default-shadow;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 99;

  &[aria-expanded="true"] {
    .configuration {
      &__header {
        .configuration__total--price {
          opacity: 0;
        }
      }
    }
  }

  &[aria-expanded="false"] {
    .configuration {
      &__header {
        .configuration__total--price {
          opacity: 1;
        }
      }

      &__product, &__total-container, &__actions {
        display: none;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--no-products {
      border-bottom: none;
      padding-bottom: 0;
    }

    &__title {
      display: flex;
      gap: .5rem;

      .material-symbols-outlined {
        font-size: 1rem;
      }
    }
  }

  &__title {
    font-size: 1rem;
  }

  &__product {
    border-top: 1px solid $border;
    padding-top: .5rem;

    &:last-of-type {
      border-bottom: none;
    }

    &__title-container, &__price-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5rem;
    }

    &__title-container, &__dimensions-container {
      margin-bottom: .2rem;
    }

    &__title {
      font-weight: bold;
      color: $primary
    }

    &__dimensions {
      color: $text-lighter
    }

    &__price {
      &--total {
        font-weight: bold;
        margin-left: auto;
      }
    }

    &__accessories {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      margin-bottom: .2rem;

      &:first-of-type {
        margin-top: .2rem;
      }

      &__text {
        display: flex;
        align-items: center;
        color: $primary;
        font-weight: bold;

        .material-symbols-outlined {
          margin: 0 .5rem 0 1rem;
          font-size: 1rem;
        }
      }
    }
  }

  &__actions {
  }

  &__total-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__total {
    &--title, &--price {
      font-weight: bold;
    }
  }
}

.mobile {
  display: none;
}

@media (max-width: 600px) {
  .configuration {
    top: 1rem;
    bottom: initial;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

